<!-- 内部客服 -->
<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <div class="app-customer-service-container nav-page-container">
      <div class="message-container">
        <page-container
            ref="pageContainer"
            :scroll-top.sync="scrollTop"
        >
          <div v-if="messageType === '02' && !messageList.length" class="no-message">
            <div>暂无系统消息</div>
            <van-button type="primary" round @click="$router.back()">返回</van-button>
          </div>

          <div
              v-for="{id, direction, contentType, messageContent, status, createTime} in messageList"
              :key="id"
              class="user-message-item"
              :class="{
              'left-user': direction === 1,
              'right-user': direction === 2,
            }"
          >
            <van-image v-if="direction === 2" class="avatar" :src="userInfo.avatar || ''">
              <template #error>
                <div class="avatar-error">{{ userInfo.nickname }}</div>
              </template>
            </van-image>
            <van-image v-if="direction === 1" class="avatar" :src="storeInfo.logo || ''">
              <template #error>
                <div class="avatar-error">{{ storeInfo.name }}</div>
              </template>
            </van-image>

            <div class="messageContent-container">
              <div class="time">{{ createTime }}</div>
              <div
                  v-if="+contentType === 1"
                  class="messageContent"
              >
                <span>{{ messageContent }}</span>
              </div>
              <van-image v-if="+contentType === 2" :src="messageContent"/>
            </div>

            <div
                v-if="direction === 2"
                class="status"
                :class="{ unread: status === '01' }"
            >{{ formatStatusText(status) }}
            </div>

          </div>
        </page-container>
      </div>
      <div v-if="!initLoading && messageType === '01'" class="bottom-bar-container">
        <van-field
            v-model.trim="inputContent"
            type="textarea"
            rows="1"
            :clearable="true"
            :autosize="true"
            :show-word-limit="false"
            :border="false"
            maxlength="200"
            label-width="0"
            label=""
            placeholder="请输入您要咨询的问题"
        />
        <van-uploader
            v-if="!inputContent"
            :after-read="sendImg"
        >
          <div class="add-img-btn">
            <van-icon name="add-o"/>
          </div>
        </van-uploader>
        <div
            v-else
            class="add-img-btn"
            @click="sendMsg"
        >
          <span>发送</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStore } from '@/api/shop';
import { getUserInfo } from '@/api/user';
import { createMessage, pagingQueryMessage, uploadImg } from '@/api/sessionMessage';
import { Toast } from 'vant';
import { mapState } from 'vuex';

export default {
  name: 'customerService',
  data() {
    return {
      inputContent: '',
      from: {},
      storeInfo: {},
      userInfo: {},
      initLoading: true,

      scrollTop: null,
      messageType: '01', // 01 普通消息 02 系统消息
      messageDataList: [], // 聊天数据
      listLoading: false,
      isCreated: false,
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.homeCustomerService.refreshing,
    }),
    messageList() {
      let arr = [];
      // this.messageDataList.map(({ records }) => arr.concat(records));

      this.messageDataList.forEach(({ records }) => {
        arr = [...arr, ...records];
      });

      return arr;
    },
  },
  created() {
    this.messageType = this.$route.query.messageType || this.messageType;

    // this.$watch('storeRefreshing', () => {
    //   if (!this.storeRefreshing)
    //     return;
    //   this.$store.commit('homeCustomerService/setRefreshing', false);

      this.init().then(() => this.isCreated = true);

    // }, { immediate: true });
  },
  activated() {
    this.isCreated && this.init();
    this.$refs.pageContainer.scrollToTop(this.scrollTop);
  },
  methods: {
    formatStatusText(status) {
      return {
        '01': '未读',
        '02': '已读',
        '03': '已删除',
      }[status] || '';
    },

    async init() {
      this.messageDataList = [];
      let promise1 = getStore().then(res => {
        this.storeInfo = res || {};
      });

      let promise2 = getUserInfo().then(res => {
        this.userInfo = res || {};
      });

      await Promise.all([promise1, promise2]);
      this.initLoading = false;

      this.$watch('scrollTop', () => {
        if (!this.scrollTop && !this.listLoading) {
          this.viewMoreMessages();
        }
      }, { immediate: true });
    },

    viewMoreMessages(queryPage) {
      return new Promise(resolve => {
        let { messageDataList } = this;
        let page = 0;
        if (queryPage) {
          page = queryPage;
        } else if (messageDataList.length) {
          let { pages, current } = messageDataList[0];
          if (current < pages) {
            page = current + 1;
          } else {
            resolve();
            return Toast('没有更多记录了');
          }
        } else {
          page = 1;
        }

        this.listLoading = true;

        let $scroller = this.$refs.pageContainer.$el;
        let height = $scroller.scrollHeight;

        pagingQueryMessage({ messageType: this.messageType, current: page }).then(res => {
          this.listLoading = false;

          let { page: data } = res;
          if (data.records && data.records.length) {
            data.records.reverse();
            if (queryPage) {
              this.messageDataList = [data];
            } else {
              this.messageDataList = [data, ...messageDataList];
            }

            this.$nextTick(() => {
              let scrollTop = page === 1 ? $scroller.scrollHeight : $scroller.scrollHeight - height;
              this.$refs.pageContainer.scrollToTop(scrollTop);
            });

          }
          resolve();
        }).catch(() => {
          resolve();
        });
      });

    },

    // 发送字符串
    sendMsg() {
      let { inputContent } = this;
      if (inputContent.length) {
        createMessage({
          agencyId: this.storeInfo.id,
          contentType: '1',
          messageContent: inputContent,
          userId: this.userInfo.id,
        }).then(res => {
          if (res === true) {
            this.inputContent = '';
            this.viewMoreMessages(1);
          }
        });
      }
    },
    // 发送图片地址
    sendImg({ file }) {
      let formData = new FormData();
      formData.append('file', file);
      uploadImg(formData).then(res => {
        let { url } = res || {};
        if (url) {
          createMessage({
            agencyId: this.storeInfo.id,
            contentType: '2',
            messageContent: url,
            userId: this.userInfo.id,
          }).then(res => {
            if (res === true) {
              this.viewMoreMessages(1);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.app-customer-service-container {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  .message-container {
    flex: 1;
    position: relative;

    .no-message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      width: 100%;
      height: 100%;

      & > .van-button {
        margin-top: 20px;
        padding: 0 16px;
      }
    }
  }

  .user-message-item {
    display: flex;
    margin: 12px;

    .avatar {
      flex-shrink: 0;
      width: 50px;
      height: 50px;

      .avatar-error {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 14px;
        color: #666;
        background-color: #fff;
        border-radius: 5px;
      }
    }

    .time {
      color: #888;
    }

    .messageContent-container {
      margin: 4px 10px 10px 10px;

      .messageContent {
        display: inline-block;
        margin: 6px 0 0 0;
        padding: 6px 10px;
        line-height: 1.5;
        font-size: 14px;
        word-break: break-all;
        color: #333;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #f0f0f0;
      }
    }

    .status {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding-top: 13px;
      font-size: 12px;
      color: #999;

      &.unread {
        color: @primary-color;
      }
    }

    &.right-user {
      flex-direction: row-reverse;

      .messageContent-container {
        text-align: right;

        .messageContent {
          background-color: @primary-color;
          color: #fff;
          border: 1px solid @primary-color;

          span {
            display: inline-block;
            text-align: left;
          }
        }
      }
    }
  }

  .bottom-bar-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 0 calc(10px + constant(safe-area-inset-bottom)) 10px;
    padding: 10px 0 calc(10px + env(safe-area-inset-bottom)) 10px;
    width: 100%;
    background-color: #fff;

    .van-cell.van-field {
      padding: 8px 16px;
      line-height: 1.2;
      background-color: #f4f4f4;
      border-radius: 10px;
    }

    .add-img-btn {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 60px;

      .van-icon.van-icon-add-o {
        font-size: 24px;
      }

      & > span {
        color: @primary-color;
      }
    }
  }
}
</style>
