var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"title":_vm.$route.meta.name,"left-arrow":""},on:{"click-left":function($event){return _vm.$router.back()}}}),_c('div',{staticClass:"app-customer-service-container nav-page-container"},[_c('div',{staticClass:"message-container"},[_c('page-container',{ref:"pageContainer",attrs:{"scroll-top":_vm.scrollTop},on:{"update:scrollTop":function($event){_vm.scrollTop=$event},"update:scroll-top":function($event){_vm.scrollTop=$event}}},[(_vm.messageType === '02' && !_vm.messageList.length)?_c('div',{staticClass:"no-message"},[_c('div',[_vm._v("暂无系统消息")]),_c('van-button',{attrs:{"type":"primary","round":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("返回")])],1):_vm._e(),_vm._l((_vm.messageList),function(ref){
var id = ref.id;
var direction = ref.direction;
var contentType = ref.contentType;
var messageContent = ref.messageContent;
var status = ref.status;
var createTime = ref.createTime;
return _c('div',{key:id,staticClass:"user-message-item",class:{
            'left-user': direction === 1,
            'right-user': direction === 2,
          }},[(direction === 2)?_c('van-image',{staticClass:"avatar",attrs:{"src":_vm.userInfo.avatar || ''},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('div',{staticClass:"avatar-error"},[_vm._v(_vm._s(_vm.userInfo.nickname))])]},proxy:true}],null,true)}):_vm._e(),(direction === 1)?_c('van-image',{staticClass:"avatar",attrs:{"src":_vm.storeInfo.logo || ''},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('div',{staticClass:"avatar-error"},[_vm._v(_vm._s(_vm.storeInfo.name))])]},proxy:true}],null,true)}):_vm._e(),_c('div',{staticClass:"messageContent-container"},[_c('div',{staticClass:"time"},[_vm._v(_vm._s(createTime))]),(+contentType === 1)?_c('div',{staticClass:"messageContent"},[_c('span',[_vm._v(_vm._s(messageContent))])]):_vm._e(),(+contentType === 2)?_c('van-image',{attrs:{"src":messageContent}}):_vm._e()],1),(direction === 2)?_c('div',{staticClass:"status",class:{ unread: status === '01' }},[_vm._v(_vm._s(_vm.formatStatusText(status))+" ")]):_vm._e()],1)})],2)],1),(!_vm.initLoading && _vm.messageType === '01')?_c('div',{staticClass:"bottom-bar-container"},[_c('van-field',{attrs:{"type":"textarea","rows":"1","clearable":true,"autosize":true,"show-word-limit":false,"border":false,"maxlength":"200","label-width":"0","label":"","placeholder":"请输入您要咨询的问题"},model:{value:(_vm.inputContent),callback:function ($$v) {_vm.inputContent=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"inputContent"}}),(!_vm.inputContent)?_c('van-uploader',{attrs:{"after-read":_vm.sendImg}},[_c('div',{staticClass:"add-img-btn"},[_c('van-icon',{attrs:{"name":"add-o"}})],1)]):_c('div',{staticClass:"add-img-btn",on:{"click":_vm.sendMsg}},[_c('span',[_vm._v("发送")])])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }